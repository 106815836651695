import React from 'react'
import Fade from 'react-reveal'

// Styled components

// Main component

interface IRevealProps {
  children: React.ReactChild
}

const Reveal: React.SFC<IRevealProps> = props => {
  return <Fade>{props.children}</Fade>
}

export default Reveal
