import styled from 'styled-components'

interface IBoxProps {
  padding?: string
  margin?: string
  textAlign?: 'left' | 'center' | 'right'
  flex?: string

  paddingLg?: string
  flexLg?: string
  textAlignLg?: string

  fontSizeSm?: string
  paddingSm?: string
}

const Box = styled.div<IBoxProps>`
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  text-align: ${props => props.textAlign};
  flex: ${props => props.flex};

  @media (max-width: ${props => props.theme.media.lg}) {
    padding: ${props => props.paddingLg};
    flex: ${props => props.flexLg};
    text-align: ${props => props.textAlignLg};
  }

  @media (max-width: ${props => props.theme.media.sm}) {
    padding: ${props => props.paddingSm};
    font-size: ${props => props.fontSizeSm};
  }
`

export default Box
