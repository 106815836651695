import styled from 'styled-components'
import Box from './Box'

interface IFlexProps {
  justifyContent?: string
  alignItems?: string
  direction?: string
  wrap?: string

  wrapLg?: string
  justifyContentLg?: string
  alignItemsLg?: string
}

const Flex = styled(Box)<IFlexProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justifyContent};
  align-items: ${props => props.alignItems};
  flex-wrap: ${props => props.wrap};

  @media (max-width: ${props => props.theme.media.lg}) {
    flex-wrap: ${props => props.wrapLg};
    justify-content: ${props => props.justifyContentLg};
    align-items: ${props => props.alignItemsLg};
  }
`

export default Flex
