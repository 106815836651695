import React from 'react'
import Text from './Text'
import Box from './Box'
import Nav from './Nav'
import styled from 'styled-components'
import LanguageSwitch from './LanguageSwitch'

// Styled components

const StyledFooter = styled.footer``

// Main component

interface IFooterProps {}

const Footer: React.SFC<IFooterProps> = (props) => {
  return (
    <StyledFooter>
      <Box textAlign='center'>
        <Text color='white' lineHeight={1.3}>
          © 2016-2022 Efficiam S.r.l. - All rights reserved
        </Text>
      </Box>

      <Box
        margin='0.8em auto 0em auto'
        textAlign='center'
        style={{ maxWidth: '31em' }}
      >
        <Text color='white' lineHeight={1.5}>
          Via Nazario Sauro 15, 35139 Padua (PD) - Via Bertolotti 7, 10121 Torino (TO) - VAT/CF/P.IVA IT05021500284 - SDI:
          M5UXCR1
        </Text>
      </Box>

      <Box margin='3em 0em 0em 0em' textAlign='center'>
        <Nav />
      </Box>

      <Box padding='1.5em 0em 0em 0em' textAlign='center'>
        <LanguageSwitch />
      </Box>
    </StyledFooter>
  )
}

export default Footer
