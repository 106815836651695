import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import theme from '../utils/theme'
import Helmet from 'react-helmet'

// Add normalize.css

import 'normalize.css'
import { prototype } from 'stream'

// i18n

// Styled components

const StyledLayout = styled.div<ILayoutProps>`
  background-image: ${(props) => props.backgroundImage};
  background-position: ${(props) => props.backgroundPosition};
  background-size: ${(props) => props.backgroundSize};
  background-repeat: no-repeat;

  height: 100%;

  @media (max-width: ${(props) => props.theme.media.lg}) {
    background-size: ${(props) => props.backgroundSizeLg};
  }

  @media (max-width: ${(props) => props.theme.media.sm}) {
    background-position: ${(props) => props.backgroundPositionSm};
    background-size: ${(props) => props.backgroundSizeSm};
  }
`

// Main component

interface ILayoutProps {
  children: React.ReactChild

  backgroundImage?: string
  backgroundPosition?: string
  backgroundSize?: string

  backgroundSizeLg?: string
  backgroundSizeSm?: string

  backgroundPositionSm?: string
}

const Layout: React.SFC<ILayoutProps> = (props) => {
  return (
    <>
      <Helmet>
        <title>Efficiam</title>
        <link
          href='https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400'
          rel='stylesheet'
        />

        <style>{`
          html {
            font-size: 100%;
          }

          *, *::before, *::after {
            box-sizing: border-box;
          }

          body {
            background-color: ${theme.colors.darkPurple};
            font-family: 'Source Sans Pro', sans-serif;
          }
        `}</style>
      </Helmet>

      <ThemeProvider theme={theme}>
        <StyledLayout {...props} style={{ overflow: 'hidden' }}>
          {props.children}
        </StyledLayout>
      </ThemeProvider>
    </>
  )
}

export default Layout
