import React from 'react'
import styled from 'styled-components'
import Link from './Link'
import Text from './Text'
import { injectIntl, IntlShape } from 'gatsby-plugin-intl'

// Styled components

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  margin-top: -1em;
  margin-right: -1.2em;

  text-align: center;
`

const Li = styled.li`
  display: inline-block;

  margin-top: 1em;
  margin-right: 1.2em;
`

const CustomLink = styled(Link)`
  opacity: 0.7;
`

// Main component

interface INavProps {
  intl: IntlShape
}

const Nav: React.SFC<INavProps> = (props) => {
  return (
    <Ul>
      <Li>
        <CustomLink to='/' activeStyle={{ opacity: 1 }}>
          <Text color='lightPurple' letterSpacing='0.07em' size='1em' uppercase>
            {props.intl.formatMessage({ id: 'nav.home' })}
          </Text>
        </CustomLink>
      </Li>

      <Li>
        <CustomLink to='/services/' activeStyle={{ opacity: 1 }}>
          <Text color='lightPurple' letterSpacing='0.07em' size='1em' uppercase>
            {props.intl.formatMessage({ id: 'nav.services' })}
          </Text>
        </CustomLink>
      </Li>

      <Li>
        <CustomLink to='/travel/' activeStyle={{ opacity: 1 }}>
          <Text color='lightPurple' letterSpacing='0.07em' size='1em' uppercase>
            {props.intl.formatMessage({ id: 'nav.travel' })}
          </Text>
        </CustomLink>
      </Li>

      <Li>
        <CustomLink to='/contact/' activeStyle={{ opacity: 1 }}>
          <Text color='lightPurple' letterSpacing='0.07em' size='1em' uppercase>
            {props.intl.formatMessage({ id: 'nav.contact' })}
          </Text>
        </CustomLink>
      </Li>

      <Li>
        <CustomLink to='/careers/' activeStyle={{ opacity: 1 }}>
          <Text color='lightPurple' letterSpacing='0.07em' size='1em' uppercase>
            {props.intl.formatMessage({ id: 'nav.careers' })}
          </Text>
        </CustomLink>
      </Li>
    </Ul>
  )
}

export default injectIntl(Nav)
