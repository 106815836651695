import React from 'react'
import styled from 'styled-components'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-intl'
import Box from './Box'
import Text from './Text'

// Styled components

interface ILanguageLinkProps {
  active?: boolean
}

const LanguageLink = styled.a<ILanguageLinkProps>`
  opacity: ${props => (props.active ? '1' : '0.7')};
  cursor: pointer;
`

const Ul = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`

const Li = styled.li`
  display: inline-block;

  &:not(:last-child) {
    margin-right: 1.2em;
  }
`

// Main component

const LanguageSwitch: React.SFC = () => {
  return (
    <Ul>
      <IntlContextConsumer>
        {(params: any) => {
          const { language, languages } = params

          return languages.map((item: any) => (
            <Li>
              <LanguageLink
                key={language}
                onClick={() => changeLocale(item)}
                active={item === language}
              >
                <Text
                  color='lightPurple'
                  letterSpacing='0.07em'
                  size='1em'
                  uppercase
                >
                  {item}
                </Text>
              </LanguageLink>
            </Li>
          ))
        }}
      </IntlContextConsumer>
    </Ul>
  )
}

export default LanguageSwitch
