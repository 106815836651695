import React from 'react'
import Sticky from 'react-stickynode'
import styled from 'styled-components'
import { Container } from '.'
import Image from './Image'
import Link from './Link'
import Nav from './Nav'
import Flex from './Flex'
import { graphql, useStaticQuery } from 'gatsby'
import Box from './Box'

// Styled components

const OuterNavbar = styled.div`
  .sticky-outer-wrapper.active & {
    background-color: ${(props) => props.theme.colors.opaqueDarkPurple};
  }
`

// Main component

interface INavbarProps {}

const Navbar: React.SFC<INavbarProps> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      logoImage: file(relativePath: { eq: "logo.svg" }) {
        ...getImage
      }
    }
  `)

  return (
    <Box padding='2em 0em 0em 0em' paddingLg='0em 0em 0em 0em' fontSizeSm='85%'>
      <Sticky innerZ={100}>
        <OuterNavbar>
          <Container>
            <Flex
              alignItems='center'
              justifyContent='space-between'
              padding='1em 0em'
              wrapLg='wrap'
              justifyContentLg='center'
            >
              <Box flexLg='0 0 100%' textAlignLg='center'>
                <Link to='/'>
                  <Image src={data.logoImage.publicURL} widthLg='2em' />
                </Link>
              </Box>

              <Box paddingLg='1em 0em 0em 0em'>
                <Nav />
              </Box>
            </Flex>
          </Container>
        </OuterNavbar>
      </Sticky>
    </Box>
  )
}

export default Navbar
