import React from 'react'
import styled from 'styled-components'

// Styled components

const Wrapper = styled.div`
  width: 100%;

  &::after {
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: ${props => props.theme.colors.gradientPurple};
  }
`

const StyledInput = styled.input`
  width: 100%;
  border: 0;
  background-color: transparent;
  padding: 1.3em 0em;

  color: ${props => props.theme.colors.white};
  font-size: 1.3em;
  outline: none;
`

// Main component

interface IInputProps {
  placeholder?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
}

const Input: React.SFC<IInputProps> = props => {
  return (
    <Wrapper>
      <StyledInput
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
      />
    </Wrapper>
  )
}

export default Input
