import React from 'react'
import styled from 'styled-components'
import { Spring } from 'react-spring/renderprops'

// Styled components

const AccordionWrapper = styled.div``

const ItemWrapper = styled.div``

const TitleWrapper = styled.div`
  cursor: pointer;
`

const ContentWrapper = styled.div`
  overflow: hidden;
`

// Main component

interface IAccordionProps<T> {
  items: Array<T>
  renderTitle: (item: T, index: number, isActive: boolean) => React.ReactNode
  renderContent: (item: T, index: number) => React.ReactNode
  keyExtractor: (item: T, index: number) => string
}

interface IAccordionState {
  openItem: string | null
}

class Accordion<T> extends React.Component<
  IAccordionProps<T>,
  IAccordionState
> {
  constructor(props: IAccordionProps<T>) {
    super(props)

    this.state = {
      openItem:
        typeof window !== 'undefined' && window.location.hash
          ? window.location.hash.substring(1)
          : null,
    }
  }

  _handleClick(key: string) {
    const { openItem } = this.state
    const newKey = openItem === key ? null : key

    this.setState({
      openItem: newKey,
    })

    if (newKey) {
      history.pushState(null, null, `#${newKey}`)
    }
  }

  render() {
    const { props, state } = this

    return (
      <AccordionWrapper>
        {props.items.map((item, index) => {
          const key = props.keyExtractor(item, index)

          return (
            <ItemWrapper key={`accordion${index}`}>
              <TitleWrapper onClick={() => this._handleClick(key)}>
                {props.renderTitle(item, index, key === state.openItem)}
              </TitleWrapper>

              <Spring
                from={{
                  height: 0,
                  opacity: 0,
                }}
                to={{
                  height: key === state.openItem ? 'auto' : 0,
                  opacity: key === state.openItem ? 1 : 0,
                }}
              >
                {springProps => (
                  <ContentWrapper style={springProps}>
                    {props.renderContent(item, index)}
                  </ContentWrapper>
                )}
              </Spring>
            </ItemWrapper>
          )
        })}
      </AccordionWrapper>
    )
  }
}

export default Accordion
