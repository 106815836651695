import styled from 'styled-components'
import theme from '../utils/theme'

interface ITextProps {
  color?: keyof typeof theme.colors
  size?: string
  uppercase?: boolean
  letterSpacing?: string
  weight?: 300 | 400
  lineHeight?: number
  maxWidth?: string
  italic?: boolean
  underline?: boolean

  sizeSm?: string
}

const Text = styled.span<ITextProps>`
  display: inline-block;

  color: ${props =>
    props.color ? props.theme.colors[props.color] : undefined};
  font-size: ${props => props.size};
  text-transform: ${props => (props.uppercase ? `uppercase` : undefined)};
  letter-spacing: ${props => props.letterSpacing};
  font-weight: ${props => props.weight};
  line-height: ${props => props.lineHeight};
  font-style: ${props => (props.italic ? `italic` : undefined)};
  text-decoration: ${props => (props.underline ? `underline` : undefined)};

  max-width: ${props => props.maxWidth};

  @media (max-width: ${props => props.theme.media.sm}) {
    font-size: ${props => props.sizeSm};
  }
`

export default Text
