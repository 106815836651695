import styled from 'styled-components'

const Button = styled.button`
  padding: 1em 1.5em;

  color: ${props => props.theme.colors.white};
  font-size: 1.2em;

  background: ${props => props.theme.colors.gradientPurple};
  border: 0;
  border-radius: 0.2em;

  outline: none;
  cursor: pointer;

  vertical-align: middle;
`

export default Button
