import React, { RefObject } from 'react'
import styled from 'styled-components'
import VanillaTilt from 'vanilla-tilt'
import Img, { FluidObject } from 'gatsby-image'

// Styled components

interface IStyledImgProps {
  radius?: string
  maxWidth?: string
  width?: string

  widthLg?: string
}

const StyledImg = styled.img<IStyledImgProps>`
  border-radius: ${props => props.radius};
  width: ${props => props.width};
  max-width: ${props => props.maxWidth};

  @media (max-width: ${props => props.theme.media.lg}) {
    width: ${props => props.widthLg};
  }
`

// Main component

interface IImageProps extends IStyledImgProps {
  src?: string

  tilt?: boolean
  style?: React.CSSProperties
  className?: string
}

class Image extends React.Component<IImageProps> {
  imageRef: RefObject<HTMLImageElement>

  constructor(props: IImageProps) {
    super(props)
    this.imageRef = React.createRef()
  }

  componentDidMount() {
    if (this.imageRef.current && this.props.tilt) {
      VanillaTilt.init(this.imageRef.current)
    }
  }

  render() {
    return (
      <StyledImg
        ref={this.imageRef as any}
        src={this.props.src}
        style={this.props.style}
        className={this.props.className}
        {...this.props}
      />
    )
  }
}

export default Image
