const theme = {
  colors: {
    white: '#ffffff',
    black: '#000000',

    darkPurple: '#100c19',
    lightPurple: '#9366db',

    silver: '#8b8791',

    opaqueDarkPurple: 'rgba(16,12,25,.95)',
    gradientPurple: 'linear-gradient(138.37deg, #9649bf 0%, #7e5ce8 100%)',
  },

  media: {
    lg: `${1000 / 16}em`,
    sm: `${450 / 16}em`,
  },
}

export default theme
