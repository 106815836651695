import styled from 'styled-components'

interface IContainerProps {
  fontSizeSm?: string
}

const Container = styled.div<IContainerProps>`
  max-width: 63em;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1em;
  padding-right: 1em;
  height: 100%;

  @media (max-width: ${(props) => props.theme.media.sm}) {
    font-size: ${(props) => props.fontSizeSm};
  }
`

export default Container
